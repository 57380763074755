import {
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Form,
  Table,
  Container
} from 'reactstrap'
import {
  useAuth
} from '../../../lib/useAuth'
import { IgtLoader } from '../../atoms'
import axios from 'axios'
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import env from '../../../lib/env'
import TagModals from '../../molecules/tagmodals/TagModals.jsx'


const TagValidation = () => {
  const auth = useAuth()
  const [tagData, setTagData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const editUsers = ['blake.borgholthaus@igt.com', 'crysta.turnage@igt.com']

  let authHeaders = auth.getAuthorizationHeaders();
  authHeaders['Authorization'] = 'Token token=dd60503fd5a2eac3d38e86237a402ee7'
  const baseUrl = env.REACT_APP_AWS_ACCOUNT_NUMBER === 123456789 ? env.REACT_APP_BASE_API_URL : 'https://api.admin-portal.igt.com'
  const axiosClient = axios.create({
    baseURL: baseUrl,
    timeout: 20000,
    headers: authHeaders
  })

  useEffect(() => {
    axiosClient.get('/tools/tags')
      .then(resp => {
        setTagData(resp.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
    // eslint-disable-next-line 
  }, [])

  const tableStyles = {
    color: 'white',
    backgroundColor:'rgba(27, 32, 99, 0.9)',
    borderColor:'rgb(0, 123, 255)',
    borderRadius: "5px",
  }

  const txtColor = {
    color:'white',
  }

  return (
    <Container fluid>
      <Row>
        <Col lg='12'>
          
          <BreadcrumbGroup
            items={[
              { 
                text: "Home", 
                href: "/auth" 
              },
              { 
                text: "Administration", 
                href: "/administration"
              },
              {
                text: "Tag Values"
              }
            ]}
            ariaLabel="Tag Values Page"
          />
        </Col>
        <br />
        <br />
      </Row>
      <>
        {isLoading && (
          <IgtLoader />
        )}
        {!isLoading &&  (
          <Row>
            <Col lg="12">
              <Form>
                <Table style={tableStyles}>
                  <thead>
                    <tr>
                      <th style={txtColor}>Cost Center</th>
                      <th style={txtColor}>SAP</th>
                      <th style={txtColor}>PPM ProjectID</th>
                      <th style={txtColor}>SAP Project Name</th>
                      <th style={txtColor}>PPM Project Name</th>
                      <th style={txtColor}>End Date</th>
                      <th style={txtColor}>Active</th>
                      {editUsers.includes(auth.user.uid) && 
                        <th style={txtColor}>Edit</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {tagData.map((tag, i) => {
                      let end_date = tag.end_date ? new Date(tag.end_date).toISOString().split('T')[0] : "N/A"
                      if (end_date !== "N/A") {
                        let day = end_date.split('-')[2]
                        let month = end_date.split('-')[1]
                        let year = end_date.split('-')[0]
                        end_date = `${month}/${day}/${year}`
                      }
                      let active = tag.active === null ? "N/A" : tag.active === true ? "TRUE" : "FALSE"
                      return (
                        <>
                          <tr>
                            <td>{tag.cost_center}</td>
                            <td>{tag.network}</td>
                            <td>{tag.project_id}</td>
                            <td>{tag.sap_project_name}</td>
                            <td>{tag.ppm_project_name}</td>
                            <td>{end_date}</td>
                            <td>{active}</td>
                            {editUsers.includes(auth.user.uid) && 
                              <td>{<TagModals tag={tag} auth={auth} axiosClient={axiosClient}/>}</td>
                            }
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </Table>
                
              </Form>
              <br/>
            </Col>
          </Row>
        )}
      </>
    </Container>
  )
}

export default TagValidation